import { Box, Flex, FormControl, FormErrorMessage, Stack } from '@chakra-ui/react';
import { sendToken } from '@components/login/requests';
import DefaultLayout from '@components/ra/common/layout/DefaultLayout';
import TextInput from '@components/ra/inputs/TextInput';
import Button from '@components/ra/interactive/Button';
import Body1 from '@components/ra/typography/Body1';
import Header2 from '@components/ra/typography/Header2';
import { Routes } from '@constants/routes';
import { textInputHeight } from '@constants/styles';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import validateEmail from '@utils/validateEmail';
import { Field, Form, Formik } from 'formik';
import router from 'next/router';

const Login = () => {
  const { localUser, setLocalUser } = useAuthenticatedContextProvider();

  const handleSubmit = async ({ email }, { resetForm }) => {
    resetForm();
    setLocalUser({ ...localUser, email });
    await sendToken(email);
    router.push(Routes.checkEmail);
  };

  return (
    <DefaultLayout>
      <Stack
        border="10px solid"
        borderColor="teal"
        px={[10, 10, 10, 100]}
        pt={['80px', '120px', '120px', 160]}
        pb={['100px', '240px', '240px', 240]}
        bg="white"
        spacing={6}
        alignItems="center"
      >
        <Header2>Login</Header2>
        <Body1 textAlign="center">We'll send a secure login link to your email.</Body1>
        <Flex>
          <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Stack alignItems="center" spacing={6}>
                  <Field name="email" type="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email && field.value.length > 4}
                        w="300px"
                        h={textInputHeight}
                      >
                        <TextInput
                          field={field}
                          id="email-input"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          autoComplete="email"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Box>
                    <Button whiteSpace="nowrap" type="submit" isLoading={isSubmitting}>
                      Send Link
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Stack>
    </DefaultLayout>
  );
};

export default Login;
